<template>

    <div class="section" id="sobre" ref="sobre">
        <div class="wrap" v-prlx="{
                reverse: true,
                speed: 0.2,
                preserveInitialPosition: false,
                //limit: { min: 0 },
                fromBottom: false
            }">

            <b-container class="fluid">
                <b-row class="px-4">
                    <b-col class="col-md-12 col-lg-5">
                        <div class="caption">
                            <h3 style="font-weigth:bold"><strong class="tit-mini-dest tit-mini-dest--dark">{{ texto_sobre.title }}</strong></h3>
                            <p v-html="texto_sobre.description"/>
                        </div>
                    </b-col>
                    <b-col class="col-md-1 col-lg-1"></b-col>
                    <b-col class="col-md-12 col-lg-6 d-flex justify-content-center">
                        <picture class="img-zoom">
                            <img :src="texto_sobre.link" alt="">
                        </picture>
                    </b-col>
                </b-row>
            </b-container>

        </div>
    </div>

</template>

<script>

export default {
    name: "App",
    props: {
        texto_sobre: {}
    },

}
</script>

<style scoped lang="scss">
@import "../assets/scss/global";


    .section{
        min-height: 50vh;
    }

    @media (max-width: $mobile){
        .row{
            flex-flow: column;
            text-align: center;
        }
    }

</style>
